import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/Login/index.vue'
// import IndexView from '../views/Index/index.vue'
import ShopIndexView from '../views/Index/shopIndex.vue'
import MyInfoView from '../views/my/index.vue'



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: "/login"
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: IndexView
  // },
  {
    path: '/shop',
    name: 'shop',
    component: ShopIndexView,
    meta:{
      showTab:true
    }
  },
  {
    path: '/myInfo',
    name: 'myInfo',
    component: MyInfoView,
    meta:{
      showTab:true
    }
  },
 

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
