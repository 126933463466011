<template>
<van-nav-bar title="兑换专区" />
<van-notice-bar  left-icon="volume-o" scrollable text="充电券兑换时间为每天上午10-12点，请在兑换时间内兑换" />
<!-- <div class="container"> -->
    <van-cell-group>
  <van-cell style="text-align: left;" title="闪开银豆" :value="skyd" />
  <van-cell style="text-align: left;" title="充电券余额" :value="couponFunds" />
</van-cell-group>
<div style="text-align: center;">


<van-card
  num="99999"
  price="0.50"
  desc="5200银豆可以兑换0.5元充电券"

  
>
<!-- <div> -->


 <template #price>
    ¥ 0.50
   
  </template>
  <template #desc>
    <p>5200银豆可以兑换0.5元充电券</p>
   
  </template>
  <template #title>
    <p>0.5元充电券</p>
   
  </template>
<!-- </div> -->
    <template #thumb>
        <img src="../../assets/Cuppon/0.5.png"/>
    </template>
    
  <template #footer>
    <van-button size="small" @click="ExchangeCoupon(5)">去兑换</van-button>
   
  </template>
</van-card>

<van-card
  num="99999"
  price="1.00"
  desc="10000银豆可以兑换1元充电券"
  title="1元充电券"
>
<template #price>
    ¥ 1.00
   
  </template>
  <template #desc>
    <p>10000银豆可以兑换1元充电券</p>
   
  </template>
  <template #title>
    <p>1元充电券</p>
   
  </template>
    <template #thumb>
        <img src="../../assets/Cuppon/1.png"/>
    </template>
  <template #footer>
    <van-button size="small" @click="ExchangeCoupon(6)">去兑换</van-button>
   
  </template>
</van-card>
<van-card
  num="99999"
 
>
<template #price>
    ¥ 2.00
   
  </template>
  <template #desc>
    <p>20000银豆可以兑换2元充电券</p>
   
  </template>
  <template #title>
    <p>2元充电券</p>
   
  </template>
    <template #thumb>
        <img src="../../assets/Cuppon/2.png"/>
    </template>
  <template #footer>
    <van-button size="small" @click="ExchangeCoupon(7)">去兑换</van-button>
   
  </template>
</van-card>
</div>
</template>

<script>
import axios from '../../axios';
import { showNotify,showSuccessToast, showFailToast  } from 'vant';
import { ref } from 'vue';
import router from '../../router'


// import img from '../../assets/Cuppon/2.png'
export default {
    setup() {
        const skyd = ref(0)
        const couponFunds = ref(0)
        const ExchangeCoupon = (exchangeId) => {
            axios.post(`/ExchangeCouponByFlash/${exchangeId}`).then(response =>{
                // console.log(response);
                if (response.code == 1){
                    // showNotify({ type: 'success', message: response.message });
                    showSuccessToast(response.message);
                }else if (response.code == 401){
                    router.push("/login")
                    // showFailToast(response.message);
                }else{
                    showFailToast(response.message);
                }

            })


        }
        const getUserYD = () =>{
            axios.post(`/GetIFlashAmount`).then(response =>{
                // console.log(response);
                if (response.code == 1){
                    skyd.value = response.data.iflashAmount
                    // showNotify({ type: 'success', message: response.message });
                    // showFailToast(response.message);
                    
                }else if (response.code == 401){
                    router.push("/login")

                }else{
                    showFailToast(response.message);
                }
                
            })
        }
        getUserYD()
        const getUserCDQ = () =>{
            axios.post(`/GetCouponFunds`).then(response =>{
                // console.log(response);
                if (response.code == 1){
                  couponFunds.value = response.data.couponFunds
                    // showNotify({ type: 'success', message: response.message });
                    // showFailToast(response.message);
                    
                }else if (response.code == 401){
                    router.push("/login")

                }else{
                    showFailToast(response.message);
                }
                
            })
        }
        getUserCDQ()

        return {
            ExchangeCoupon,
            skyd,
            couponFunds,

            
        };

    }
    
    
}
</script>


<style>
.container{
    
    margin: 10px;
}
</style>
