<template>
  <!-- <nav> -->
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  <!-- </nav> -->
  <router-view/>
  <van-tabbar v-model="active" v-if="$route.meta.showTab">
  <van-tabbar-item icon="home-o" to="/shop">首页</van-tabbar-item>
  <van-tabbar-item icon="user-o" to="/myInfo">我的</van-tabbar-item>
  <!-- <van-tabbar-item icon="friends-o">标签</van-tabbar-item>
  <van-tabbar-item icon="setting-o">标签</van-tabbar-item> -->
</van-tabbar>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  background-color: aliceblue;
  min-height: 100vh;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
<script>
import { ref } from 'vue';

export default {
  setup() {
    const active = ref(0);
    return { active };
  },
};

</script>
