<template>
    <div class="container">
      <h2>Login</h2>
      <van-cell-group inset>
        <!-- 输入任意文本 -->
        <van-field v-model="loginUsername" label="用户名" />
        <!-- 输入密码 -->
        <van-field v-model="loginPassword" type="password" label="密码" />
        </van-cell-group>
        <div style="display: flex;justify-content: center;">
        <van-button type="primary" @click="login" style="margin: 10px;">Login</van-button>
      </div>
      <!-- <p>{{ message }}</p> -->
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
import axios from '../../axios';
import { showNotify } from 'vant';
import router from '../../router'
export default {
  setup() {
    const loginUsername = ref('');
    const loginPassword = ref('');
    // const message = ref('');

    const login = () => {
      axios.post('/login', {
        username: loginUsername.value,
        password: loginPassword.value
      })
      .then(response => {
        // console.log(response);
        
        if (response.code==200){
            // 存储token
            localStorage.setItem("XToken", response.data.token)
            // this.$router.push("/dashboard")
            // console.log(222222222);
            
            router.push("/shop")
            // console.log(1111111);
            
            showNotify({ type: 'success', message: response.message });
        }else{
            showNotify({type: 'danger',  message: response.message });
        }
        
        // message.value = response.data.message;
      })
    //   .catch(error => {
    //     // message.value = error.response.data.error;
    //     console.log(error);
        
    //   });
    };

    return {
        loginUsername,
        loginPassword,
   
      login
    };
  }
};
  </script>
  <style scoped>
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    margin : 0;
    
  }

  </style>
