<template>
    <van-nav-bar title="我的" />
    <div class="container">


        <van-cell-group inset style="margin-bottom: 10px;">
            <van-field v-model="Result" is-link readonly name="datePicker" label="时间选择" placeholder="点击选择时间"
                @click="showPicker = true" />
            <van-popup v-model:show="showPicker" position="bottom">
                <van-date-picker :columns-type="columnsType" v-model="currentDate" title="选择时间"
                    @cancel="showPicker = false" @confirm="onConfirm" />
            </van-popup>
            <!-- <van-cell title="单元格" value="内容" label="描述信息" /> -->
        </van-cell-group>
        <van-cell-group inset style="margin-bottom: 10px;">
            <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" inset>
                <van-cell v-for="item in list" :key="item" title="兑换充电券" :label="item.tAddTime" :value="-item.iFlashMoney"/>
            </van-list>
        </van-cell-group>

    </div>
</template>


<script>
import axios from '../../axios';
import { ref } from 'vue';
import router from '../../router'

export default {
    setup() {
        
        const showPicker = ref(false);
        const onConfirm = ({ selectedValues }) => {
            showPicker.value = false;
            Result.value = selectedValues.join('-');

            finished.value = false
            pageNo.value = 0
            list.value = []
            onLoad()
        };
        // const currentDate = ref(['2024','08']);
        const pageNo = ref(0)
        const pageSize = ref(20)
        // const month= ref(Result)

        const today = new Date();
        const currentYear = today.getFullYear().toString();
        const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0'); // 获取当前月份并补零
        const currentDate = ref([currentYear, currentMonth]);
        const Result = ref(`${currentYear}-${currentMonth}`)
        const columnsType = ['year', 'month'];
        // const month = ref(`${currentYear}-${currentMonth}`)

        const list = ref([]);
        const loading = ref(false);
        const finished = ref(false);

        const onLoad = () => {
            // 异步更新数据
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            GetExchangeList()
        };


        const GetExchangeList = () => {
            pageNo.value += 1
            loading.value = true
            axios.post(`/GetExchangeList?pageNo=${pageNo.value}&pageSize=${pageSize.value}&month=${Result.value}`).then(response => {
                if (response.code == 1) {
                    if (pageNo.value == 1) {
                        list.value = response.data.flashDetailRecords
                    } else {
                        list.value = [...list.value, ...response.data.flashDetailRecords]
                    }
                    if (list.value.length == response.data.currentSize) {
                        finished.value = true

                    }
                    loading.value = false
                    // showNotify({ type: 'success', message: response.message });
                    // showSuccessToast(response.message);
                } else if (response.code == 401){
                    router.push("/login")
                    // showFailToast(response.message);
                }

            })

        }
        GetExchangeList()

        return {
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 5, 1),
            currentDate,
            columnsType,
            onConfirm,
            Result,
            showPicker,
            list,
            onLoad,
            loading,
            finished,

        };
    },
};


</script>

<style>
.container {
    margin-bottom: 5px;
}
</style>